//modal actions
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

//http
export const _FETCHING = "_FETCHING";
export const _FULFILLED = "_FULFILLED";
export const _REJECTED = "_REJECTED";
export const _CLEAR = "_CLEAR";
export const _NEXT = "_NEXT";
export const _ADD_DATA = "_ADD_DATA";
export const _REMOVE_DATA = "_REMOVE_DATA";
export const _UPDATE_DATA = "_UPDATE_DATA";
export const _SET_ACTIVE = "_SET_ACTIVE";

//forms
export const _ON_CHANGE = "_ON_CHANGE";
export const _UPDATE_OPTIONS = "_UPDATE_OPTIONS";
export const _SET_ERR = "_SET_ERR";
export const _SET_VALUE = "_SET_VALUE"
export const _CLEAR_DES = "_CLEAR_DES"
export const _ADD_FORM = "_ADD_FORM";
export const _REMOVE_FORM = "_REMOVE_FORM";
export const _LOAD_FORM = "_LOAD_FORM";

//landing page
export const SIGNUP_FORM = "SIGNUP_FORM";
//register page
export const REGISTER_BASIC_INFO = "REGISTER_BASIC_INFO";
export const REGISTER_PHY = "REGISTER_PHY";
export const REGISTER_EDU = "REGISTER_EDU";
export const REGISTER_ASTRO = "REGISTER_ASTRO";
export const REGISTER_HABITS = "REGISTER_HABITS";
export const REGISTER_FAMILY = "REGISTER_FAMILY";
export const REGISTER_DESCRIPTION = "REGISTER_DESCRIPTION";
//edit profile
export const EDITUSER_BASIC_INFO = "EDITUSER_BASIC_INFO";
export const EDITUSER_PHY = "EDITUSER_PHY";
export const EDITUSER_EDU = "EDITUSER_EDU";
export const EDITUSER_ASTRO = "EDITUSER_ASTRO";
export const EDITUSER_HABITS = "EDITUSER_HABITS";
export const EDITUSER_FAMILY = "EDITUSER_FAMILY";
export const EDITUSER_DESCRIPTION = "EDITUSER_DESCRIPTION";
//partner preferences
export const PARTNER_BASIC_INFO = "PARTNER_BASIC_INFO";
export const PARTNER_PHY = "PARTNER_PHY";
export const PARTNER_EDU = "PARTNER_EDU";
export const PARTNER_ASTRO = "PARTNER_ASTRO";
export const PARTNER_HABITS = "PARTNER_HABITS";
export const PARTNER_FAMILY = "PARTNER_FAMILY";
export const PARTNER_DESCRIPTION = "PARTNER_DESCRIPTION";

export const QUICK_SEARCH_FORM = "QUICK_SEARCH_FORM";
//advanced search
export const ADV_BASIC = "ADV_BASIC";
export const ADV_EDU = "ADV_EDU";
export const ADV_ASTRO = "ADV_ASTRO";
export const ADV_FILTER = "ADV_FILTER";
export const ADV_HABITS = "ADV_HABITS";

//manage forms
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//edit privacy setting
export const PROFILE_SETTING = "PROFILE_SETTING";

//forgot form
export const FORGOT_FORM = "FORGOT_FORM";

//reset pwd form
export const RESET_FORM = "RESET_FORM";

//contact us form
export const CONTACT_FORM = "CONTACT_FORM";

//DEactivate us form
export const DEACTIVE_FORM = "DEACTIVE_FORM";

//user actions
export const UPDATE_USER = "UPDATE_USER";
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const UPDATE_PROFILE = "UPDATE";
export const LOGOUT = "LOGOUT";
export const SIGNUP = "SIGNUP";
export const OTP = "OTP";
export const OTP_VERIFY = "OTP_VERIFY";
export const RESEND_OTP = "RESEND_OTP";
export const UPLOAD_PHOTOS = "UPLOAD_PHOTOS";
export const DELETE_PHOTOS = "DELETE_PHOTOS";
export const SET_PRO_PIC = "SET_PRO_PIC";
export const PARTNER_PREFER = "PARTNER_PREFER";
export const DASHBOARD = "DASHBOARD";
export const DASHBOARD_PROFILES = "DASHBOARD_PROFILES";
export const PROFILE = "PROFILE";
export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const MATCHES = "MATCHES";
export const MATCH_DETAIL = "MATCH_DETAIL";
export const MY_PROFILE = "MY_PROFILE";
export const CHANGE_PWD = "CHANGE_PWD";
export const LIST_NOTI = "LIST_NOTI";
export const PRIVACY = "PRIVACY";
export const FORGOTPWD = "FORGOTPWD";
export const RESETPWD = "RESETPWD";
export const CONTACT_US = "CONTACT_US";


// Matches
export const YET_TO_BE_VIEWED = "YET_TO_BE_VIEWED";
export const NEW_MATCHES = "NEW_MATCHES";
export const MY_COMMUNITY = "MY_COMMUNITY";

// Acitivities Matches
export const PROFILE_VIEWED_BY_ME = "PROFILE_VIEWED_BY_ME"
export const PROFILE_SHORTLISTED_BY_ME = "PROFILE_SHORTLISTED_BY_ME"
export const NUMBER_VIEWED_BY_ME = "NUMBER_VIEWED_BY_ME"
export const WHO_VIEWED_MY_PROFILE = "WHO_VIEWED_MY_PROFILE"
export const WHO_SHORTLISTED_YOU= "WHO_SHORTLISTED_YOU"
export const WHO_VIEWED_NUMBER = "WHO_VIEWED_NUMBER"


//user activities
export const SHORT_LIST_MATCH = "SHORT_LIST_MATCH";
export const REJECT_MATCH = "REJECT_MATCH";
export const REQUEST_PHOTO = "REQUEST_PHOTO";
export const REQUEST_NUMBER = "REQUEST_NUMBER";
export const ACCEPT_DENY = "ACCEPT_DENY";

//matches
export const DASH_MATCHES = "DASH_MATCHES";
export const ACTV_MATCHES = "ACTV_MATCHES";

//request macthes
export const SENT_MATCHES = "SENT_MATCHES";
export const RECIEVED_MATCHES = "RECIEVED_MATCHES";

export const RECENT_MATCHES = "RECENT_MATCHES";
export const RECOM_MATCHES = "RECOM_MATCHES";
export const SEARCH_MATCHES = "SEARCH_MATCHES";

export const PHONE_MATCHES = "PHONE_MATCHES";
export const INTEREST_MATCHES = "INTEREST_MATCHES";
export const NUMBER_REQUEST_MATCHES = "NUMBER_REQUEST_MATCHES";
export const PHOTO_REQUEST_MATCHES = "PHOTO_REQUEST_MATCHES";

export const SIMILAR_MATCHES = "SIMILAR_MATCHES";

//upgrade
export const LIST_PACKAGES = "LIST_PACKAGES";
export const PAY_NOW = "PAY_NOW";
export const RAZOR_PAY = "RAZOR_PAY";
export const CHOOSE_PACKAGE = "CHOOSE_PACKAGE";

//chat
export const SEND_INTEREST = "SEND_INTEREST";
export const LIST_CHAT_USERS = "LIST_CHAT_USERS";
export const LOCAL_CHAT_USERS = "LOCAL_CHAT_USERS";
export const LIST_CHAT_ROOM_MESSAGES = "LIST_CHAT_ROOM_MESSAGES";
export const CREATE_ROOM = "CREATE_ROOM";
export const ACCEPT_ROOM = "ACCEPT_ROOM";
export const CLEAR_ACTIVE_ROOM = "CLEAR_ACTIVE_ROOM";

//urls
export const signup_url = "users/";
export const signin_url = "auth/login/";
export const logout_url = "auth/logout/";
export const register_url = "users/register_2/";
export const otp_url = "users/otp_verify/";
export const resendotp_url = "auth/resend-otp/";
export const uploadphotos_url = "users/photo_upload/";
export const partnerpreference_url = "users/partner_preferences/";
export const dashboard_url = "users/profile/dashboard/";
export const matches_url = "users/profile/matches/";
export const activities_url = "users/profile/matrimony/";
export const user_activities_url = "activities/";
export const profile_url = "users/profile/";
export const search_url = "users/search-profile/";
export const requests_url = "activities/requests/";
export const notifications_url = "activities/notification";
export const changepwd_url = "auth/change-password/";
export const privacy_url = "users/privacy/";
export const recentjoin_url = "users/recently-profile/";
export const packages_url = "transactions/package/";
export const transaction_url = "transactions/";
export const paysuccess_url = "transactions/payment_success/";
export const myprofile_url = "users/profile/my_profile/";
export const chats_url = "chats/";
export const offers_url = "offers/";
export const forgot_url = "auth/forgot-password/";
export const resetpwd_url = "auth/reset-password/";
export const issues_url = "issues/";
export const changeMobile_url = "auth/change-mobile/";
export const changeEmail_url = "auth/change-email/";
export const sendVerifyEmail_url = "auth/send-verification/email/";
export const verifyEmail_url = "auth/verify/email/";