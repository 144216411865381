import {
    _FETCHING, _FULFILLED, _REJECTED, _CLEAR, MATCH_DETAIL, SHORT_LIST_MATCH,
    REJECT_MATCH, LIST_NOTI, PHONE_MATCHES, INTEREST_MATCHES, SEARCH_MATCHES, RECENT_MATCHES,
    NUMBER_REQUEST_MATCHES, PHOTO_REQUEST_MATCHES, DASH_MATCHES, ACTV_MATCHES, SENT_MATCHES,
    RECIEVED_MATCHES, RECOM_MATCHES, _SET_ACTIVE, ACCEPT_DENY, SIMILAR_MATCHES, DASHBOARD_PROFILES,
    YET_TO_BE_VIEWED, NEW_MATCHES, MY_COMMUNITY, PROFILE_VIEWED_BY_ME, PROFILE_SHORTLISTED_BY_ME, NUMBER_VIEWED_BY_ME,
    WHO_VIEWED_MY_PROFILE, WHO_SHORTLISTED_YOU, WHO_VIEWED_NUMBER
} from "../actions/constants";
import { httpResultsReducer, httpArrayReducer, httpObjectReducer } from "./httpreducer";
import createMatchesWithId, { createMatchList } from "./creatematch";
import creatematches from './newmatches';

//intial match detail
const matchDetailInitial = {
    fetching: false, data: {}, error: false
};

export const matchDetail = (state = matchDetailInitial, action) => {
    switch (action.type) {
        case `${MATCH_DETAIL}${_FETCHING}`:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case `${MATCH_DETAIL}${_FULFILLED}`:
            return {
                ...state,
                fetching: false,
                data: action.payload
            }
        case `${MATCH_DETAIL}${_REJECTED}`:
            return {
                ...state,
                fetching: false,
                error: action.payload
            }
        case `${SHORT_LIST_MATCH}${_FULFILLED}`:
            return {
                ...state,
                data: {
                    ...state.data,
                    is_shortlist: state.data.id && state.data.id === action.payload.id && action.payload.is_shortlist,
                    is_ignore: state.data.id === action.payload.id && action.payload.is_ignore
                }
            }
        case `${REJECT_MATCH}${_FULFILLED}`:
            return {
                ...state,
                data: {
                    ...state.data,
                    is_shortlist: state.data.id && state.data.id === action.payload.id && action.payload.is_shortlist,
                    is_ignore: state.data.id === action.payload.id && action.payload.is_ignore
                }
            }
        case `${MATCH_DETAIL}${_CLEAR}`:
            return { ...state, ...matchDetailInitial }
        default:
            return state
    }
}

//create matches with id
const matchWithId = (name) => (state = { id: null }, action) => {
    if (action.type === name + _SET_ACTIVE) {
        return {
            ...state,
            id: action.id
        }
    }
    if (
        action.id &&
        (
            action.type.indexOf(name) > -1 ||
            [`${SHORT_LIST_MATCH}${_FULFILLED}`, `${REJECT_MATCH}${_FULFILLED}`, `${ACCEPT_DENY}${_FULFILLED}`]
                .includes(action.type)
        )
    ) {
        return {
            ...state,
            [action.id]: createMatchesWithId(name)(state[action.id], action)
        }
    }
    return state
}


//list dash Matches
export const dash_matches = matchWithId(DASH_MATCHES);

//list actv Matches
export const actv_matches = matchWithId(ACTV_MATCHES);

//list req
export const sent_matches = matchWithId(SENT_MATCHES);
export const recieved_matches = matchWithId(RECIEVED_MATCHES);

//list notifications
export const list_noti = httpResultsReducer(LIST_NOTI);

//dashboard matches
export const recent_matches = httpArrayReducer(RECENT_MATCHES);

export const dashboard_profiles = httpObjectReducer(DASHBOARD_PROFILES);

//manage macthes
export const phone_matches = createMatchesWithId(PHONE_MATCHES);
export const int_matches = createMatchesWithId(INTEREST_MATCHES);
export const nu_re_matches = createMatchesWithId(NUMBER_REQUEST_MATCHES);
export const ph_re_matches = createMatchesWithId(PHOTO_REQUEST_MATCHES);

//search matches
export const search_matches = createMatchesWithId(SEARCH_MATCHES);
export const recom_matches = createMatchesWithId(RECOM_MATCHES);

export const similar_matches = createMatchList(SIMILAR_MATCHES);


// Matches
export const yet_to_be_viewed = creatematches(YET_TO_BE_VIEWED)
export const new_matches = creatematches(NEW_MATCHES)
export const my_community = creatematches(MY_COMMUNITY)


// Activities
export const profile_viewed_by_me = creatematches(PROFILE_VIEWED_BY_ME)
export const profile_shortlisted_by_me = creatematches(PROFILE_SHORTLISTED_BY_ME)
export const number_viewed_by_me = creatematches(NUMBER_VIEWED_BY_ME)
export const who_viewed_my_profile = creatematches(WHO_VIEWED_MY_PROFILE)
export const who_shortlisted_me = creatematches(WHO_SHORTLISTED_YOU)
export const who_viewed_number = creatematches(WHO_VIEWED_NUMBER)
